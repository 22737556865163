export function Gallery(el, config){
	self = this;
	self.el = el;
	self.config = config;
	self.directory = self.config.directory;
	self.client = self.config.client;
	self.gallery = self.el.find('#gallery');
	self.uploadForm = self.el.find('#uploadForm');
	self.galleryLoading = self.el.find('#galleryLoading');
	self.galleryFolderTemplate = self.el.find('#galleryFolderTemplate').html();
	self.galleryFileTemplate = self.el.find('#galleryFileTemplate').html();	

	self.initiate = function(){
        console.log(Dropzone, Mustache);

        if(typeof Mustache !== "object"){
            setTimeout(self.initiate, 500);
            return;
        }
		self.loadDirectory();
		self.uploadFiles();
		self.deleteFile();
	}

	self.loadDirectory = function(){
		self.gallery.hide();
		self.galleryLoading.show();
		$.ajax({
            'url': '/gallery/'+self.client+'/load-directory',
            'method': "POST",
            'data': {
                directory: self.directory
            },
            'success' : function(data, status, xhr){
                if((status == 'success') && (xhr.status == 200)){
                	if(data.folders){
                		var htmlfolders =  Mustache.render(galleryFolderTemplate, data.folders);
                		self.gallery.html(htmlfolders);
                	}
                	if(data.files){
                		var htmlfiles =  Mustache.render(galleryFileTemplate, data.files);
                		self.gallery.html(htmlfiles);
                	}                    
                    self.galleryLoading.hide();
                    self.gallery.show();
                }
            },
            'error' : function(jqXhr, textStatus, errorMessage){
                console.log(jqXhr, textStatus, errorMessage);
            }
        });
	}

	self.uploadFiles = function(){
		Dropzone.autoDiscover = false;
        var uploader = new Dropzone('#uploadForm', {
            url: self.uploadForm.attr("action"),
            paramName: "media",
            params: {
            	'directory': self.directory
            },
            previewsContainer : self.uploadForm.data("previews-container"),
            previewTemplate : $(self.uploadForm.data("upload-preview-template")).html(),
            acceptedFiles : '*',
            maxFiles : 10,
        });
	        
        /*uploader.on('success', function(file, response){
            console.log({'event': 'success', 'file':file, 'response':response});
            file.previewElement.remove();            
        });*/

        /*uploader.on('maxfilesreached', function(){
            location.reload(true);
        });*/

        uploader.on('complete', function(file){
            if(file.status == "success"){
                uploader.removeFile(file);
            }else{
                $(file.previewElement).addClass('border-danger');
            }
            
            self.loadDirectory();
        });
	}

	self.deleteFile = function(){}

	self.initiate();
}