export function Activate(el, config){
	self = this;
	self.el = el;
	self.config = config;
	self.config.package = self.el.data('package');

	self.initiate = function(){
		self.el.find('.btn-active').each(function(key,value){
			$(value).on('click', self.changeStatus);
		});
	}

	self.changeStatus = function(e){
		if(e){
			e.preventDefault();
		}
		self.button = $(this);
		$.ajax({
			url: "/api/activate",
            type: "POST",
            data: {
	                package: self.config.package,
	                item: self.button.data('item'),
	                status: self.button.data('status')
            	}
		}).done(self.finish).fail(self.diaplayErrors);
	}

	self.finish = function(r){
		if(r.status == true){

			self.button.data('status', r.data.status);
			self.button.attr('data-status', r.data.status);
			if(r.data.status == 2){
				self.button.removeClass('badge-secondary').addClass('badge-success');
				self.button.html('<i class="fa fa-check"></i> Active');
                self.button.data('original-title', 'Click To Deactivate');
                self.button.attr('data-original-title', 'Click To Deactivate');
			}else{
				self.button.removeClass('badge-success').addClass('badge-secondary');
				self.button.html('<i class="fa fa-times"></i> Inactive');
                self.button.data('original-title', 'Click To Activate');
                self.button.attr('data-original-title', 'Click To Activate');
			}
		}
	}

	self.displayErrors = function(r){
		console.log(r);
	}

	self.initiate();
}