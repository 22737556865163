export function FileFieldBuilder(el, config){
	self = this;
	self.el = el;
	self.config = config;
    self.template = self.el.find('#template').html();

    self.loadDepencies = function(){
        /*$.getScript("https://cdnjs.cloudflare.com/ajax/libs/mustache.js/2.3.0/mustache.min.js").done(function(script, status, xhr){
            if(xhr.status == 200){
                self.initiate();
                self.el.find('#template').remove();
            }
        });*/
        if(typeof Mustache !== "object"){
            setTimeout(self.loadDepencies, 500);
            return;
        }else{
            self.initiate();
        }
    }

	self.initiate = function(){
        self.el.find('#template').remove();
		self.el.find('.addField').unbind('click').bind('click', self.addField);
        self.deleteField();
	}

    self.addField = function(e){
        if(e){
            e.preventDefault();
        }
        var id = self.config.start;

        var fieldrender = Mustache.render(self.template, {
            fid: id
        });
        self.el.prepend(fieldrender);
        id = id + 1;
        self.config.start = id;
        self.el.data('start', id);
        self.el.attr('data-start', id);
        self.deleteField();
        self.watchChange();
    }

    self.deleteField = function(){
        self.el.find('.delField').unbind('click').bind('click', function(e){
            if(e){
                e.preventDefault();
            }
            var id = $(this).closest('div').attr('id');
            var r = confirm("Are you sure you want to delete this file? This action is not reversible");
            if(r === true){
                self.el.find('#'+id).remove();
            }
        });
    }

    self.watchChange = function(){
        self.el.find("input:file").unbind('change').bind('change', self.checkFileExtension);
    }

    self.checkFileExtension = function(e){
        var filename = $(this).val();
        var extension = filename.replace(/^.*\./, '');
        switch(extension.toLowerCase()){
            case 'bmp':
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'svg':
            case 'zip':
            case 'pdf':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
                $(this).closest('div').css('border', '2px solid #00FF00');
                $(this).closest('div').find('p').empty().html('Valid File Type');
            break;

            default:
                $(this).closest('div').css('border', '2px solid #FF0000');
                $(this).closest('div').find('p').empty().html('Invalid File Type');
            break;
        }
    }

	self.loadDepencies();
}