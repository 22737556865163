export function Rangeselector(el, config){
	self = this;
	self.el = el;
	self.config = config;
	self.rangeInput = self.el;

	self.initiate = function(){		
		$('#'+self.config.list +' option').each(function(i, v){
            if($(v).val() == self.rangeInput.val()){
                $('#'+self.config.output).html($(v).prop('label'));
            }
        });
        self.changeValue();
	}

	self.changeValue = function(){
        self.rangeInput.on('change', function(){                
            var result = $(this).val();
            $('#'+self.config.list +' option').each(function(i, v){
                if($(v).val() == result){
                    $('#'+self.config.output).html($(v).prop('label'));
                }
            });
        });
    }

	self.initiate();
}