import $ from "jquery";
window.$ = window.jQuery = $;
var jQuery = $;
import moment from "moment";
window.moment = moment;

import "@popperjs/core";
import "bootstrap";
import "jquery-ui-bundle";
import "tinymce";
import "tinymce/icons/default";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-responsive-bs4";
import "datatables.net-plugins/sorting/datetime-moment";
import Mustache from "mustache";
import Dropzone from "dropzone/dist/dropzone";

import { Activate } from "./constructors/Activate.js";
import { FileFieldBuilder } from "./constructors/FileFieldBuilder.js";
import { Gallery } from "./constructors/Gallery.js";
import { Rangeselector } from "./constructors/Rangeselector.js";
/**
 * [getMultiCssScripts to load multiple style sheets]
 * @param  {[array]/string} cssFiles [path to files]
 * @return void
 */
$.getMultiCssScripts = cssFiles => {
    if (cssFiles) {
        if ($.isArray(cssFiles)) {
            for (i = 0; i < cssFiles.length; i++) {
                $("<link/>", {
                    href: cssFiles[i],
                    rel: "stylesheet",
                    type: "text/css",
                    media: "screen"
                }).appendTo("head");
            }
        } else {
            $("<link/>", {
                href: cssFiles,
                rel: "stylesheet",
                type: "text/css",
                media: "screen"
            }).appendTo("head");
        }
    }
};

/**
 * [getMultiJsScripts to load multiple js scripts]
 * @param  {[array]/string} jsFiles [path to files]
 * @return object
 */
$.getMultiJsScripts = jsFiles => {
    if (jsFiles) {
        var _arr = $.map(jsFiles, function(scr) {
            return $.getScript(scr);
        });

        _arr.push(
            $.Deferred(function(deferred) {
                $(deferred.resolve);
            })
        );

        return $.when.apply($, _arr);
    }
};

$.getRawURL = () => {
    let href = document.location.href;
    let location = href.split("/");
    let domain = location[2].toString();

    return location[0] + "//" + location[2];
};

$.getBaseURL = () => {
    return $.getRawURL() + "/js";
};

$(document).ready(function() {
    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="_token"]').attr("content")
        }
    });
    let baseurl = $.getBaseURL();

    if ($(".AKA_CONST").length) {
        $(".AKA_CONST").each(function(i, obj) {
            let data = $(obj).data();
            if (data.init == "Activate") {
                new Activate($(obj), data);
            }
            if (data.init == "FileFieldBuilder") {
                new FileFieldBuilder($(obj), data);
            }
            if (data.init == "Gallery") {
                new Gallery($(obj), data);
            }
            if (data.init == "Rangeselector") {
                new Rangeselector($(obj), data);
            }
        });
    }
    /*Bootstrap tool tip*/
    /*$('[data-toggle="tooltip"]').tooltip();*/
    $(".datepickerParent").each(function(index, value) {
        $(this)
            .find(".datepicker")
            .datepicker({
                dateFormat: "d MM yy",
                altField: $(this).find("#actualDate"),
                altFormat: "yy-mm-dd"
            });
    });

    $(".btn-delete").on("click", function() {
        let tr = $(this).closest("tr");
        let text = $(this).data("title");
        let r = confirm(
            "Are you sure you want to delete item '" +
                text +
                "'? This action can't be reversed"
        );
        if (r == true) {
            $.ajax({
                url: $(this).data("href"),
                type: "POST",
                data: {
                    item: $(this).data("item")
                }
            }).done(function(r) {
                if (r.status == true) {
                    tr.remove();
                }
            });
        }
    });

    /*Datatable*/
    if ($(".dataTable").length) {
        $(".dataTable").each(function(i, obj) {
            $.fn.dataTable.moment("DD MMMM, YYYY");
            $(obj)
            .on('init.dt', function() {
                $('.dataTables_wrapper input[type=search]').attr('tabindex', '1');
            })
            .on('search.dt', function(e) {
                var counter = 2;
                $('tbody tr', e.target).each(function(i, elem) {
                    $('td:eq(0) a', elem).attr('tabindex', counter);
                    counter = counter + 1;
                });
            })
            .DataTable({
                responsive: true,
                searching: true,
                ordering: true,
                tabindex: false
            });
        });
        /*let funcDatatable = () => {

                $.getMultiJsScripts([baseurl+"/libs/datatables/datatables.min.js"]).done(function(script, status, xhr){

                $.getMultiCssScripts(baseurl+"/libs/datatables/datatables.min.css");

                $('.dataTable').each(function(i, obj){
                    $(obj).DataTable();
                });

            }).fail(() => {
                setTimeout(funcDatatable(), 500)
            })
        }
        funcDatatable()*/
    }

    /*tinymce*/
    if ($(".wysiwyg").length) {
        $(".wysiwyg").each(function(index, value) {
            var id = $(value).attr("id");
            tinymce.baseURL = baseurl + "/libs/tinymce";
            tinymce.init({
                selector: "#" + id,
                branding: false,
                height: 300,
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount"
                ],
                toolbar:
                    "undo redo | formatselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                inline_styles: true,
                forced_root_block: "p",
                theme_advanced_toolbar_location: "top"
            });
        });
    }

    $(".nav-link-collapse").on("click", function(e) {
        e.preventDefault();
        $(this).toggleClass("collapsed");
        let href = $(this).data("href");
        $(href).toggleClass("show");
    });

    /**
     * Manage Navigation selection
     * @return {[type]} [description]
     */
    $("#nav_type_page").on("click", function() {
        $(".page").removeClass("hide");
        $(".url").addClass("hide");
        $(".package").addClass("hide");
    });
    $("#nav_type_url").on("click", function() {
        $(".url").removeClass("hide");
        $(".page").addClass("hide");
        $(".package").addClass("hide");
    });
    $("#nav_type_package").on("click", function() {  
        $(".package").removeClass("hide");
        $(".page").addClass("hide");
        $(".url").addClass("hide");
    });

    var updateStagingLink = false;

    // Create and delete staging link ajax call
    $('body').on('click', '.btn-staging-link-create,.btn-staging-link-delete', function(ev) {
        ev.preventDefault();
        var that = this;
        var parent = $(that).parent();
        $.ajax($(this).attr('href'), {
            success: function(data, textStatus) {
                $(that).replaceWith('<div class="message">' + data.status + '</div>');
                updateStagingLink = setInterval(function() {
                    $.ajax($(that).data('update'), {
                        success: function(data, textStatus) {
                            if(data.status == 'added' || data.status == 'deleted') {
                                $('.message', parent).replaceWith(data.html);
                                $('#project_staging_url').html(data.project_staging_url);
                                $('#project_staging_url').parent().attr('href', data.project_staging_url);
                                clearInterval(updateStagingLink);
                            }
                        }
                    });
                }, 5000);
            }
        });
    });

});
